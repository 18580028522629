.animation-box {
	position:relative;
	/* PJTWAUS-1 20200120 modify */
	.play-animaion-btn {
		position:absolute;
		top:20px;
		right:20px;
		z-index:9; 
		text-indent:-9999px;
		width:36px;
		height:36px;		
		white-space:nowrap;
		overflow:hidden;		
		@include screen(custom, max, $max-sm) {
			width:29px;
			height:29px;
		}		
		&.acc-btn {
			background:url('/lg5-common/images/components/ico_AD-on.svg');	
			background-size:36px 36px; 	 
		}
		&.ani-btn {
			background:url('/lg5-common/images/components/ico_AD-off.svg');	
			background-size:36px 36px;	 
		}
		@include screen(custom, max, $max-sm) {
			&.acc-btn {
				
				background-size:29px 29px;
			}
			&.ani-btn {
				
				background-size:29px 29px;
			}
		}
	}	
	@include screen(custom, max, $max-sm) {
		.animation-area {
			//display: none;
	}	
}
/* //PJTWAUS-1 20200120 modify  */
	.animation-area {
		position: absolute;
		top: 0;
		left: 0;
		width:100%;
		height:100%;
		z-index: 1;
		text-indent: -9999em;
		/* PJTWAUS-1 modify */
		&.acc-animation-content {
			display:none;
		}
		/* //PJTWAUS-1 modify */
		video {
			display: block;
			width: 100%;
			height: 100%;
		}
		@include screen(custom, min, $min-md) {
			video.mobile {display:none;}
		}
		@include screen(custom, max, $max-sm) {
			video.pc {display:none;}
		}
		.controller-wrap {
			position: absolute;
			left: 50%;
			transform:translateX(-50%);
			bottom: 0;
			display: block;			
			/* PJTWAUS-1 : 20200226 modify */
			margin-bottom: 43px;
			@include screen(custom, max, $max-sm) {
				margin-bottom: 30px;
			}
			/*// PJTWAUS-1 : 20200226 modify */
			button {
				display: none;
				text-indent: -9999em;
				width: 32px;
				height: 32px;
				border: none;
				background-color: rgba(0,0,0,0.75);
				background-position: 50% 50%;
				background-repeat: no-repeat;
				border-radius: 50%;
				cursor: pointer;
				padding:0;
				@include screen(custom, max, $max-sm) {
					width:25px;
					height:25px;
				}
				&.active {
					display: block;
				}
				&.play {
					background-image: url('/lg5-common/images/common/icons/btn-aniplay.svg');
					background-size:32px 32px;
				}
				&.pause {
					background-image: url('/lg5-common/images/common/icons/btn-anipause.svg');
					background-size:32px 32px;
				}
				@include screen(custom, max, $max-sm) {
					&.play {
						background-image: url('/lg5-common/images/common/icons/btn-aniplay-m.svg');
						background-size:25px 25px;
					}
					&.pause {
						background-image: url('/lg5-common/images/common/icons/btn-anipause-m.svg');
						background-size:25px 25px;
					}
				}
			}			
		}
	}
}
