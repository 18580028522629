#modal_pdp_detail_gallery {
	@include screen(custom, max, 1069) {
		&:before {
			display:none;
		}
	}
}

.modal {
	.modal-dialog {
		max-width:100%;
		&.modal-pdp-gallery {
			width:1066px;
			margin-left:-537px;
			@include screen(custom, max, 1069) {
				width:100%;
				top:0;left:0;
				margin-left:0;
				margin-top:0;
			}
			iframe {
				width:100%;height:100%;border:0;
			}
			.modal-body {
				touch-action: manipulation;
				position:relative;
				height:640px;
				padding:0 80px 0 0;
				@extend %clearfix;
				@include screen(custom, max, $max-sm) {
					//height:667px;
					height:617px;
					padding:90px 0 0 0;
				}
				&.append-target {
					padding-top:65px;padding-bottom:30px;
					@include screen(custom, max, $max-sm) {
						padding:50px 0 0;
					}
				}
				.default{
					position:relative;
					float:right;
					width:calc(100% - 156px);
					height:100%;
					@include screen(custom, max, $max-sm) {
						float:none;
						width:100%;
						height:auto;
					}
					.slick-list {
						overflow:hidden;
						height:640px;
						width: 100%;
						@include screen(custom, max, $max-sm) {
							//height:445px;
							height:395px;
						}
					}
					.slick-track {
						overflow:hidden;
						height:100%;
					}
					.item {
						float:left;
						width:848px;
						height:100%;
						text-align:center;
					}
					.slick-slide{
						&:focus{
							outline: none;
						}
					}
					.zoom-area {
						width:100%;
						height:100%;
						cursor: url('/lg5-common/images/components/zoomin_cursor.png'), url('/lg5-common/images/components/zoomin_cursor.cur'), pointer;
						&.zoomout {
							cursor: url('/lg5-common/images/components/zoomout_cursor.png'), url('/lg5-common/images/components/zoomout_cursor.cur'), pointer;
						}
					}
					img { 
						position: absolute;
						top:0;bottom:0;left:0;right:0;
						margin: auto;
						max-width:100%;
						max-height:100%;
						transition: transform 200ms ease-in-out 0s;
						&.pc{
							display:block;
						}
						&.mobile{
							display:none;
						}
						@include screen(custom, max, $max-sm) {	
							transition: none;
							&.pc{
								display:none;	
							}
							&.mobile{
								display:block;
								margin: 0 auto;
							}
						}
					}

					// iframe contents (youtube, brightcove, 360view)
					.iframe-area {
						width: 100%;
						height: 100%;
					}
				}
				.gallery-thumbnail {
					float:left;
					width:156px;
					padding-top:34px;
					//text-align:center;

					@include screen(custom, max, $max-sm) {
						display:none;
					}
					ul {
						//display:inline-block;
						width:60px;
						margin:0;
						vertical-align:top;
						display:flex;
						flex-wrap:wrap;
						flex-direction:column;
						height:580px;
					}
					li {
						//display:block;
						border:1px solid $line-gray;
						//margin:4px 0 0;


						display:inline-block;
						margin:0 2px 4px;
						a {
							display:block;
							width:58px;height:58px;
							text-align:center;
						}
						img {
							max-width:100%;
						}
						&:first-child {margin-top:0;}
						&:hover,
						&.active {
							border-color:$line-carmine;
						}
						&.view {
							border-color:transparent;
							a {
								overflow:hidden;text-indent:200%;white-space:nowrap;
								background:url('/lg5-common/images/components/gallery-360vr.svg') no-repeat 0 0;
							}
							&:hover a,
							&.active a {
								border-color:transparent;
								background-image: url(/lg5-common/images/components/gallery-360vr-carmine.svg)
							}
						}
						&.video {
							a:before {width:32px;height:32px;margin:-16px 0 0 -16px;}
						}
					}
				}
				.thumbnail{
					overflow:hidden;
					float:left;
					width:156px;
					@include screen(custom, min, $min-md) {
					//LGEGMO-5525 modify
						ul {
							display:flex;
							flex-direction:column;
							flex-wrap:wrap;
							height:640px;
							padding:34px 12px 0 16px;
						}
					//LGEGMO-5525 modify
					}
					@include screen(custom, max, $max-sm) {
						float:none;
						position:relative;
						text-align:center;
						width:330px;
						padding:0 30px;
						margin:37px auto 0 auto;
					}
					.slick-prev{
						border:none;
						width:20px;
						height:20px;
						margin-left:20px;
						text-indent:-99999em;
						background:url(/lg5-common/images/common/icons/arrow-up-black.svg) no-repeat;
						@include screen(custom, max, $max-sm) {
							position:absolute;
							left:-5px;
							top:35px;
							margin-top:0;
							margin-left:0;
							background:url(/lg5-common/images/common/icons/arrow-left-black.svg) no-repeat;
						}
						&.slick-disabled{
							background:url(/lg5-common/images/common/icons/arrow-up-gray.svg) no-repeat;
							@include screen(custom, max, $max-sm) {
								background:url(/lg5-common/images/common/icons/arrow-left.svg) no-repeat;
							}
						}	
						
					}
					.slick-next{
						border:none;
						width:20px;
						height:20px;
						margin-left:20px;
						margin-top:4px;
						text-indent:-99999em;
						background:url(/lg5-common/images/common/icons/arrow-down-black.svg) no-repeat;
						@include screen(custom, max, $max-sm) {
							position:absolute;
							right:-4px;
							top:35px;
							margin-top:0;
							margin-left:0;
							background:url(/lg5-common/images/common/icons/arrow-right-black.svg) no-repeat;
						}
						&.slick-disabled{
							background:url(/lg5-common/images/common/icons/arrow-down-gray.svg) no-repeat;
							@include screen(custom, max, $max-sm) {
								background:url(/lg5-common/images/common/icons/arrow-right.svg) no-repeat;
							}
						}	
					}
					.slick-list {
						overflow:hidden;
						// height:auto !important;
						// max-height:462px;
						// padding:0 !important;
						margin-top:6px;
						width: 60px;
						@include screen(custom, max, $max-sm) {
							width:279px;
						}
					}
					.item {
						//LGEGMO-5525 modify
						a {
							display: block;
							/* PJTWAUS-1 20200129 modify */
							position:relative;
							width: 58px;
							height: 58px;
							line-height: 56px;
							/* //PJTWAUS-1 20200129 modify */				
							&:focus {
								outline:0;
								position:relative;
								&:after {
									content:'';
									display:block;
									position:absolute; 
									top:2px;
									left:2px; 
									width:54px;
									height:54px;
									outline: -webkit-focus-ring-color auto 1px;												
									outline:focus-ring-color auto 1px;
								}
							}		
						}
						//LGEGMO-5525 modify
						position:relative;
						width:60px;
						height:60px;
						margin:0 0 4px;
						border:1px solid $line-gray;
						text-align:center;
						cursor:pointer;
						line-height:60px;
						overflow:hidden;
						@include screen(custom, max, $max-sm) {
							float:left;
							margin:0 10px 0 0;
							/* PJTWAUS-1 20200129 modify **/
							&.slick-current {
								border-color:$line-carmine;
								a {									
									&:after {
										content:'';
										display:block;
										border:1px solid $line-carmine;
										width:58px;
										height:58px;
										position:absolute;
										top:0px;
										left:0px;										
									}
								}
							}
							/* //PJTWAUS-1 20200129 modify **/
						}
						/* PJTWAUS-1 20200129 modify */
						@include screen(custom, min, $min-md) {
							&:hover {
								width:62px;
								height:62px;
								border-color:$line-carmine;
								border-width:2px;
								margin:-1px -1px 3px;
							}
							&.slick-current {
								border-color:$line-carmine;
								width:62px;
								height:62px;
								border-color:$line-carmine;
								border-width:2px;
								margin:-1px -1px 3px;
							}
						}
						/* //PJTWAUS-1 20200129 modify */
						&.video:before {
							content: '';
							display: inline-block;
							position: absolute;
							left: 50%;
							top: 50%;
							width: 50%;
							height: 50%;
							margin: -15px 0 0 -15px;
							background: url(/lg5-common/images/components/btn-play.svg) no-repeat 0 0;
							background-size: 100% 100%;
						}
						//LGEGMO-5525 modify
						&.video {
							a:before {
								display: none;
							}
						}
						//LGEGMO-5525 modify
						&.view {
							background: url(/lg5-common/images/components/gallery-360vr.svg) no-repeat 0 0;
							background-size: 100% 100%;
							border-color:transparent;
							&:hover,
							&.slick-current {
								border-color:transparent;
								background-image: url(/lg5-common/images/components/gallery-360vr-carmine.svg)
							}
						}
						/* PJTWAUS-1 20200129 modify */
						/*@include screen(custom, min, $min-md) {
							&:hover {
								border-color:$line-carmine;
								//border-width:2px;
							}
						}*/
						&.slick-current {
							a {
								&:focus {
									outline:0;
									position:relative;
									&:after {
										content:'';
										display:block;
										position:absolute; 
										top:2px;
										left:2px; 
										width:54px;
										height:54px;
										outline: -webkit-focus-ring-color auto 1px;												
										outline:focus-ring-color auto 1px;
									}
								}
							}														
						}
						/* //PJTWAUS-1 20200129 modify */
					}
					img { 
						max-width:100%;
						display:inline-block;
						/* PJTWAUS-1 20200122 modify */
						//margin-top:-2px;
						vertical-align:top;
						/* //PJTWAUS-1 20200122 modify  */
					}
				}
				.pdp-pagination {
					display:none;
					@include screen(custom, max, $max-sm) {
						display:block;
						position:absolute;
						top:-31px;
						width:270px;
						li {
							display:none;
							font-size:14px;
							color:$color-dimgray;
							&.slick-active {
								display:block;
							}
						} 
					}
				}
				.pdp-visual-pagination {
					position: absolute;
					bottom: 86px;
					left: 50%; transform: translatex(-50%);
					font-size:14px;
					line-height:20px;
					color:$color-dimgray;
					@include screen(custom, min, $min-md) {
						display:none;
					}
				}
				.control {
					position:absolute;
					right:30px;
					top:50%;
					width:30px;
					margin-top:-45px;
					padding-left:1px;

					@include screen(custom, max, $max-sm) {
						right:auto;
						left:50%;
						top:30px;
						width:97px;
						height:40px;
						margin:0 0 0 -52px;
						padding:0;
					}
					&:before{
						content:"";
						position:absolute; 
						top:50%;
						left:0;
						width:30px;
						margin-top:-9px;
						border-bottom:1px solid $line-gray;
						@include screen(custom, max, $max-sm) {
							top:0;
							left:50%;
							margin:0 0 0 2px;
							width:auto;
							height:40px;
							border-right:1px solid $line-gray;
							border-bottom:none;
						}
					}
					button {
						touch-action: manipulation;
						cursor:pointer;
					}
					.plus{
						display:block;
						width:28px; 
						height:28px;
						margin-bottom:35px;
						text-indent:-9999em;
						border:none;
						background:url(/lg5-common/images/common/icons/zoomin.svg) center no-repeat;
						@include screen(custom, max, $max-sm) {
							float:right;
							margin-top:6px;
							margin-bottom:0;
						}
					}
					.minus{
						display:block;
						width:28px; 
						height:28px;
						margin-bottom:17px;
						text-indent:-9999em;
						border:none;
						background:url(/lg5-common/images/common/icons/zoomout.svg) center no-repeat;
						@include screen(custom, max, $max-sm) {
							float:left;
							margin-top:6px;
							margin-left:6px;
							margin-bottom:0;
						}
					}
					
				}
				/* PJTWAUS-1 20200121 modify */
				button.change-video, button.acc-change-video {
					overflow:hidden;
					position: fixed;
					right: 22px;
					top: 59px;		
					width:36px;
					height:36px;					
					white-space:nowrap;
					text-indent:200%;
					border:0;
					z-index: 1;
					cursor:pointer;	
					@include screen(custom, max, $max-sm) {
						top:38px;
						right:13px;
						width:32px;
						height:32px;
					}	
				}
				button.change-video {
					background:url('/lg5-common/images/components/ico_AD-off.svg') no-repeat center center;			 
					background-size:100% 100%;			
				}
				button.acc-change-video {
					background:url('/lg5-common/images/components/ico_AD-on.svg') no-repeat center center;			
					background-size:100% 100%;
				}
				/* //PJTWAUS-1 20200121 modify */
			}
		}
	}

	&.loaded-iframe {
		.control {
			display: none !important;
		}
	}
}
