@charset 'UTF-8';

@import './setting/variables';
@import './components/video-asset';
@import './components/animation-asset';

@import './components/filter-default'; // filter default css (filter: C0007/C0026)
@import './components/no-content';
@import './components/colorchip';

@import './modal/pdp-summary-gallery';

// component container 
.iw_placeholder .iw_component {border-top:1px solid transparent;margin-top:-1px;}
.component-wrap {
	position:relative;width:100%;clear:both;
	@extend %clearfix;
	max-width:1600px;
	margin:50px auto; // 50px : component top bottom margin for desktop
	//padding:0 30px;
	&.bg-whitesmoke {background-color:$bg-whitesmoke;}
	@include screen(custom, max, (1630)) {
		margin-left:-15px;
		margin-right:-15px;
		padding:0; 
		width:auto;
	}
	@include screen(custom, max, $max-sm) {
		margin:25px -15px; // 60px : component top bottom margin for mobile
	}
	&.zero-top {
		margin-top:0;
		// 상단 여백 없음 선택 시, 기본 여백 있는 경우 추가 처리
		.component {
			
		}
	}
	&.zero-btm {
		margin-bottom:0;
		// 하단 여백 없음 선택 시, 기본 여백 있는 경우 추가 처리
		.component {
			
		}
	}
	.component {
		position:relative;
		margin:0 auto;
		// 좌우에 Slick 화살표가 들어가는 경우 
		&.component-outer-box,
		.component-outer-box {
			max-width:(1284px+160px);
			margin:0 auto;
			padding-left:80px;
			padding-right:80px;
			.component-inner-box {
				position:relative;
				overflow:visible;
				width:100%;
				padding:0;
				margin:0;
			}
			@include screen(custom, max, $max-sm) {
				padding-left:24px;
				padding-right:24px;
			}
			.slick-prev,
			.slick-next {
				position:absolute;
				top:50%;
				margin-top:-12px;
				border:0;
				background:none;
				padding:0;
				width:23px;
				height:40px;
				cursor:pointer;
				svg {
					display: block;
				}
				color: $color-gray;
				&:hover,
				&:focus {
					//color: $color-nightrider;
					color:$color-black;
				}
				@include screen(custom, max, $max-sm) {
					display:none;
				}
			}
			.slick-prev {
				left:-50px;
			}
			.slick-next {
				right:-50px;
			}
		}
		// LGEGMO-5525 add
		.slick-slider {
			.slick-arrow.slick-disabled {opacity:0.3;}
		}
		// LGEGMO-5525 add
	}

	// text-align
	.align-left {
		text-align:left;
	}
	.align-right {
		text-align:right;
	}
	.align-center {
		text-align:center;
	}

	// video title copy, play button

	.video,
	.animation-box,
	.visual-area.image  {
		.caption {
			width:100%;
			height:39px;
			background-color:rgba(0,0,0,0.35);
			color:$color-white;
			font-size:14px;
			line-height: 24px;
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 5;
			padding: 7px 0 0 15px;
			text-align: left;
			box-sizing: border-box;
			@include screen(custom, max, $max-sm) {
				padding: 2px 0 0 10px;
				height:29px;
			}
		}
	}
	.video {
		a {
			display:block;
			&:before {
				content:'';
				display: inline-block;
				position:absolute;
				left:50%;
				top:50%;
				/* LGEGMC-432 20200812 modify */
				width:48px;
				height:48px;
				margin:-24px 0 0 -24px;
				background:url('/lg5-common/images/components/btn-play.svg') no-repeat 0 0;
				background-size:100% 100%;
				border-radius:50% 50%;
				background-color:rgba(0,0,0, 0.25);
				// @include screen(custom, max, $max-sm){
				// 	width:48px;
				// 	height:48px;
				// 	margin:-24px 0 0 -24px;
				// 	background:rgba(0,0,0, 0.25) url('/lg5-common/images/components/btn-play.svg') no-repeat 0 0;
				// }
				/* //LGEGMC-432 20200812 modify */		
			}			
		}
		/* PJTWAUS-1 20200109 modify  */
		.acc-video-content {
			position:absolute;
			top:20px;
			right:20px;
			width:36px;
			height:36px;
			overflow:hidden;
			z-index:9;
			text-indent:2000%;
			white-space:nowrap;	
			@include screen(custom, max, $max-sm) {
				width:29px;
				height:29px;
			}			
			&:before {
				content:'';
				display:inline-block;
				position:absolute;
				top:0;
				right:0;
				left:inherit;					
				width:36px;
				height:36px;
				margin:0;
				background:url('/lg5-common/images/components/ico_AD-on.svg');	
				background-size:36px 36px; 	
				border-radius:inherit;
				@include screen(custom, max, $max-sm) {
					width:29px;
					height:29px;					
					background-size:29px 29px;
				}
			}
			
		} 
		/* //PJTWAUS-1 20200109 modify */
	}	
	// font opion style
	.font-regular {
		@include font-family($font-regular);
	}
	.font-bold {
		@include font-family($font-bold);
	}
	.font-regular-italic {
		@include font-family($font-regular, italic);
	}
	.font-bold-italic {
		@include font-family($font-bold, italic);
	}
	.font-underline {
		text-decoration:underline;
	}
	// bv_star style custom
	[data-bv-rating] .bv_main_container, 
	[data-bv-show=inline_rating] .bv_main_container {
		display:inline-block!important;
	}
	
	[data-bv-rating] .bv_main_container .bv_averageRating_component_container, 
	[data-bv-rating] .bv_main_container .bv_numReviews_component_container, 
	[data-bv-show=inline_rating] .bv_main_container .bv_averageRating_component_container, 
	[data-bv-show=inline_rating] .bv_main_container .bv_numReviews_component_container,
	.bv_stars_component_container {
		display:inline-block!important;
		vertical-align:middle!important;
	}
	/* PJTWAUS-1 20200109 modify  */
	.hidden {		
		position:absolute;
		width:0;
		height:0;
		line-height:1px; 
		left:-9999px;
		top:auto;
		overflow:hidden; 
		&.mobile {display:none;}
		&.tablet {display:none;}
		@include screen(custom, max, $max-sm) {
			&.pc {display:none;}
			&.tablet {display:none;}
			&.mobile {display:inline-block;}
		}	
		@include screen(custom, min, $min-md) {
			@include screen(custom, max, $max-lg) {
				&.pc {display:none;}
				&.tablet {display:inline-block;}
				&.mobile {display:none;}
			}
		}
		
	}
	/* //PJTWAUS-1 20200109 modify */
}

