 	.video-asset {
	width: 100%;
	height: 56.25vw;
	position: relative;
	overflow: hidden;
	iframe {
		border: none;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		// transform: translateY(-50%);
	}
}

.visual-area.video-box {
	position: relative;
	.see-video {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}
}
.link-text.see-video {
	color: $color-nightrider;
	&.ico-left {
		&:before {
			@extend %linkicon;
			margin-right:8px;
			width:20px;
			height:20px;
			background:url('/lg5-common/images/components/btn-play-black.svg') no-repeat;
			background-size:20px 20px;
			vertical-align:middle;
			margin-top:-3px;
		}
	}
	/* PJTWAUS-1 20200108 modify */
	&.acc-video-content {
		margin-right:8px;
		&:before {
			width:44px;
			height:24px;
			background:url('/lg5-common/images/components/AD_animation-on.svg') no-repeat !important; 
		}		
	}
	/* //PJTWAUS-1 20200108 modify  */
	.text-white & {
		color: $color-white;
		&:before {
			background: url(/lg5-common/images/components/btn-play.svg) no-repeat; 
		    background-size: 20px 20px;
		}
		/* PJTWAUS-1 20200110 modify */
		&.acc-video-content {			
			&:before {
				width:44px;
				height:24px;
				background:url('/lg5-common/images/components/AD_animation-w_on.svg') no-repeat !important; 
			}		
		}
		/* //PJTWAUS-1 20200110 modify  */
	}
}

// video close common button
.video-box-closeset {
	/* PJTWAUS-1 modify */
	&.video-modal {
		/* PJTWAUS-1 20200108 modify  */
		button.close-video {
			top: 20px; 			
		}
		button.change-video, button.acc-change-video {
			top:63px;
		}		 
		/* //PJTWAUS-1 20200108 modify */
	}
	/* //PJTWAUS-1 modify */
	/* PJTWAUS-1 20200108 modify  */
	button.close-video {
		overflow:hidden;
		position: absolute;		
		/*right: 20px;
		top: 62px;
		width:51px;
		height:51px;
		padding:10px;
		background:url('/lg5-common/images/common/icons/modal-close.svg') no-repeat 10px 10px;
		background-color: $bg-white !important;
		background-size:31px 31px;*/ 
		right: 20px;
		top: 62px;		
		width:36px;
		height:36px;
		background:url('/lg5-common/images/components/ico_close.png') no-repeat center center;			
		background-size:36px 36px;				
		white-space:nowrap;
		text-indent:200%;
		border:0;
		z-index: 1;
		cursor:pointer;
		cursor: pointer;
		@include screen(custom, max, $max-md) {			
			/*width:35px;
			height:35px;
			padding:5px;
			background-position:5px 5px;
			background-size:25px 25px;*/
			background:url('/lg5-common/images/components/ico_close-m.png') no-repeat center center;	 
			background-size:36px 36px;				
		}
		/*@include screen(custom, max, $max-xs) {
			width:26px;
			height:26px;
			background-size:16px 16px;
		}*/
	}
	button.change-video, button.acc-change-video {
		overflow:hidden;
		position: absolute;
		right: 20px;
		top: 105px;		
		width:36px;
		height:36px;					
		white-space:nowrap;
		text-indent:200%;
		border:0;
		z-index: 1;
		cursor:pointer;		
	}
	button.change-video {
		background:url('/lg5-common/images/components/ico_AD-off.svg') no-repeat center center;			 
		background-size:36px 36px;			
	}
	button.acc-change-video {
		background:url('/lg5-common/images/components/ico_AD-on.svg') no-repeat center center;			
		background-size:36px 36px;
	}
	/* //PJTWAUS-1 20200108 modify */
}


// Video asset inside components
.video-box {
	position: relative;
	&.slick-active.carousel-box {
		background-color: $color-black;
	}
	.video-asset {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: $color-black;
		z-index: 1;
	}
}
.video-box:not(.slick-slide) {
	@include screen(custom, max, $max-sm){
		.text-area {
			opacity: 1 !important;
		}
	}
}
.video-modal {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 99999;  /* //PJTWAUS-1 20191227 modify */
	background-color: $color-white;
	.modal-video-asset {
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		transform: translateY(-50%);
		overflow: hidden;
		.video-asset {
			max-width: 1284px;
			max-height: 722px;
			margin: 0 auto;
			@media screen and (min-aspect-ratio: 16/9) {
				height: 100vh;
			}
		}
	}
	&.youtube {
		.video-asset {
			@media screen and (max-aspect-ratio: 16/9) {
				height: calc((100vw - 15px) * (9/16));
			}
		}
	}
	&.brightcove {
		.video-asset {
			@media screen and (max-aspect-ratio: 16/9) {
				height: calc((100vw) * (9/16));
			}
		}
	}
}

#videoPlayerCode {
	width:100%;height:100%;
	.video-js {width:100%;height:100%;}
	.vjs-poster {width:100%;height:100%;}
}
