.filter-open-box{
	display:none;
	height:69px;
	margin-bottom:30px;
	text-align:center;
	background:$bg-whitesmoke;
	@include screen(custom, max, 850) {
		display:block;
	}
	a{
		font-size:14px;
		padding-top:21px;
		width: 100%;
		height: 100%;
		display: block;
		@include font-family($font-semibold);
		color:$color-nightrider !important;
		text-decoration:none;
		&:link,
		&:visited,
		&:hover,
		&:active {color:$color-nightrider !important;}
		&.ico-right:after {
			@extend %linkicon;
			transform: rotate(90deg);
			margin-left:6px;
			background:url('/lg5-common/images/common/icons/link-right-dark.svg') no-repeat 2px 0;
		}
		
	}
}
.filter-box{
	float:left;
	width:279px;
	margin-right:48px;
	border-top:1px solid $line-gray;
	@include screen(custom, max, 850) {
		float:none;
		display:none;
		width:100%;
		margin-right:0;			
		background:$bg-whitesmoke;
		&.open{
			@include screen(custom, max, 850) {
				display:block;
				margin-bottom:30px;
				.filter-result{
					display:block;
				}
			}
		}
	}
	
	.guide-for-user {
		position: absolute;
		z-index:-1;
		left:-100000px;
		opacity:0;
	}
	.filter-result{
		display:none;
		height:69px;
		border-bottom:1px solid $line-gray;
		text-align:center;
		a {
			font-size:14px;
			display: block;
			width: 100%;
			height: 100%;
			padding-top:21px;
			@include font-family($font-semibold);
			color:$color-nightrider !important;
			text-decoration:none;
			&:after {
				@extend %linkicon;
				transform: rotate(-90deg);
				margin-left:6px;
				background:url('/lg5-common/images/common/icons/link-right-dark.svg') no-repeat -2px 0;
			}
		}
	}
	.option-box {
		padding-bottom:17px;
		border-bottom:1px solid $line-gray;
		&.close {float:none !important;opacity:1 !important;}
		@include screen(custom, max, 850) {
			margin:0 24px 0 24px;
		}
		.title {
			position:relative;
			margin-top:18px;
			strong{
				font-size:16px;
				@include font-family($font-semibold);
				color:$color-black;
			}
			.btn-list {
				@include screen(custom, max, 850) {
					position:absolute; 
					display:block;
					top:7px;
					right:10px;
					width:12px; 
					height:12px;
					text-indent:-99999em;
					right:0;
					background:url(/lg5-common/images/common/icons/accordion-expand-dimgray.svg) no-repeat;
					&.active {
						background:url(/lg5-common/images/common/icons/accordion-fold-dimgray.svg) no-repeat;
					}
				}
				display: none;
			}
		} 
		.option-list{
			.list-box-type1{
				margin-top:24px;
				margin-bottom:0;
				padding-bottom:2px;
				@include screen(custom, max, 850) {
					margin-top:19px;
					padding-bottom:0;
					margin-bottom:10px;
				}
				li {
					padding-left:11px;
					margin-bottom:13px;
					@include screen(custom, max, 850) {
						padding-left:0;
						margin-bottom:17px;
						&:last-child{
							margin-bottom:0;
						}
					}
				}
			}

			.list-box-type2{
				margin-top:0;
				margin-bottom:0;
				padding-top:7px;
				padding-bottom:4px;
				@include screen(custom, max, 850) {
					padding-top:6px;
					padding-bottom:1px;
				}
				li {
					padding-left:10px;
					margin-top:13px;
					@include screen(custom, max, 850) {
						padding-left:0;
						margin-top:14px;
						padding-bottom:3px;
						&:last-child{
							margin-bottom:0;
						}
					}
				}
			}

			.checkbox-box {
				.text{
					padding-left:28px;
					@include screen(custom, max, 850) {
						padding-left:26px;
					}
				}
			}
			
			.swatch-list-box{
				@extend %clearfix;
				margin:24px 9px 14px 9px;
				@include screen(custom, max, 850) {
					margin:19px 1px 9px 1px;
				}
				li{
					float:left;
					text-indent:-99999px;
					margin-right:4px;
					width:30px;
				}
				.swatch {
					text-indent:-99999em;
					overflow: visible;
					position: relative;
					float: left;
					margin: 4px 5px 4px;
					width: 20px;
					height: 20px;
					padding: 0;
					color: transparent;
					cursor: pointer;
					vertical-align: top;
					border-radius: 10px;
					border:1px solid #9c9c9c;
					box-sizing:border-box;
					&.disabled {
						opacity: 0.4;
						cursor:default;
						input[type=checkbox]:checked+:after {
							border:none;
						}
					}
					&+:before {
						content: '';
						position:absolute;
						left:-5px;
						top:-5px;
						display:block;
						width:28px;
						height:28px;
						border:1px solid transparent;
						box-sizing:border-box;
						border-radius:14px;
					}					
					input[type="checkbox"]{
						&:checked {
							&+:after {
							content: '';
							position:absolute;
							left:-5px;
							top:-5px;
							width:28px;
							height:28px;
							border:1px solid $line-carmine;
							box-sizing:border-box;
							border-radius:14px;
							
							}
						}
						&:focus {
							&+:before {
							content: '';
							position:absolute;
							left:-5px;
							top:-5px;
							width:28px;
							height:28px;
							border:1px solid $line-dimgray;
							opacity: 0.5;
							box-sizing:border-box;
							}
						}
					}
				}
			}
		}
		.tooltip {
			vertical-align:top;
			.tooltip-wrap {
				top:3px;
			}
			.btn-tooltip-question span {
				background:#999;
				color:#fff;
				&:before {
					background-image:url('/lg5-common/images/common/icons/info.svg');
				}
			}
			.tooltip-inner {
				color:#333;
				.btn-area {
					padding:14px 0 0 0;
					a.link-text {
						color:#333;
					}
					a.link-text.ico-right:after {
						background-image:url('/lg5-common/images/common/icons/link-right-nightrider.svg');
					}
				}
			}
			@include screen(custom, max, $max-sm) {
				.tooltip-area.top-right:not(.out) {
					left:50%;
					margin-left:-110px;
					&:before {
						left:50%;
						margin-left:-6px;
					}
				}
			}
		}
		.title .tooltip {
			.tooltip-wrap {
				top:1px;
			}
		}
		.slider-wrap{
			margin:36px 0 8px 20px;
			@include screen(custom, max, 850) {
				margin:31px 0 3px 20px;
			}
		}
		
		.slider-range{
			position:relative;
			border-bottom:2px solid $line-gray;
			width:241px;
			
			margin-bottom:8px;
			@include screen(custom, max, 850) {
				width:calc(100% - 20px);
			}
			.ui-slider-handle {
				position:absolute;
				top:-8px;
				width:18px;
				height:18px;
				margin-left:-9px;
				border-radius:50%;
				border:2px solid $line-carmine;
				background:$bg-white;		
			}
			.ui-slider-range{
				position:absolute;
				bottom:-2px;
				width:100%;
				height:2px;
				background:$bg-carmine;
			}		
		}
		.price-range{
			position:relative;
			height:27px;
			p {
				position:absolute;
				top:2px;
				font-size:14px;
				height:20px;
			}
			.min-box{
				left:-10px;
			}
			.max-box{
				right:10px;
				text-align:right;
			}
			
		}
		@include screen(custom, max, 850) {
			&.close{
				.btn-list {
					background:url(/lg5-common/images/common/icons/accordion-expand-dimgray.svg) no-repeat;
				}
				.option-list,.slider-wrap{
					display:none;	
				}
			}
			&.open{
				.btn-list {
					background:url(/lg5-common/images/common/icons/accordion-fold-dimgray.svg) no-repeat;
				}
				.option-list,.slider-wrap{
					display:block;	
				}
			}
		}
	} 
	.etc-box {
		padding-top:24px;
		@include screen(custom, max, 850) {
			padding-bottom:14px;
			margin:0 24px 0 24px;
		}
		.link-text {
			margin-bottom:10px;
			color:$color-dimgray;
			display:block;
			cursor: pointer;
			&.ico-right{
				text-decoration:none;
				text-transform: uppercase;
				@include font-family($font-bold);
				color:$color-nightrider;
				&:after {
					background: url('/lg5-common/images/common/icons/link-right-nightrider.svg') no-repeat 0 1px;
				}
			}
		}
	}
}
.result-box{
	float:right;
	width:calc(100% - 327px);
	z-index:1;
	position:relative;
	@include screen(custom, max, 850) {
		float:none;
		width:100%;
	}
	@include screen(custom, max, $max-xs) {
		float:none;
		width:100%;
	}
	
	.sort-box {
		position:relative;
		min-height:65px;
		// margin-bottom:4px;
		padding:15px 0 15px 24px;
		background:$bg-whitesmoke;
		@include screen(custom, max, 850) {
			padding:0 24px 32px 24px;
			margin-bottom:26px;
			border-bottom:1px solid $line-lightgray;
			background:none;
			
		} 
		/* LGEGMC-574 20200901 add */
		.sort-label{
			display: inline-block;
			vertical-align: middle;
			margin: 0 7px 0 0;
			@include font-family($font-regular);
			font-size: 16px;
			color: $color-nightrider;
			@include screen(custom, max, $max-sm) {
				display: block;
				margin: 0 0 10px 0;
				font-size: 14px;
			}
		}
		/* //LGEGMC-574 20200901 add */
		.sort-select{
			/* LGEGMC-574 20200901 add */
			display: inline-block;
			vertical-align: middle;
			/* //LGEGMC-574 20200901 add */
			width:193px;
			@include screen(custom, max, $max-md) {
				width:155px;
			}
			font-size:14px;
			height:34px;
			select{
				height:34px;
				font-size:14px;
				padding-top:0;
				padding-bottom:0;
				@include screen(custom, max, $max-sm) {
					font-size:16px; // Prevent ios auto zoom
				}
			}
			/* PJTWAUS-1 20200213 modify */
			label.mobile {
				display:none;
				@include screen(custom, max, 850) {
					&.mobile {
						display:block;
					}
				}
			}
			/* //PJTWAUS-1 20200213 modify */
			@include screen(custom, max, 850) {
				width:100%;
			}
			.chosen-container{
				font-size:14px;
				&.chosen-container-active {
					.chosen-single {
						padding-top:7px;
					}
				}
			}
			.chosen-single{
				height:34px;
				padding-top:8px;
				padding-bottom:0;
			}
		}
		.result-info{
			position:absolute;
			top:19px;
			right:25px; 
			@include screen(custom, max, 850) {
				position:relative;
				top:12px;
				right:auto;
			}
			.info {
				/* 20191111 : LGEUS-11779 add */
				&.filterTotal{
					display: none;
				}
				/* //20191111 : LGEUS-11779 add */
				font-size:16px;
				color:$color-dimgray;
				@include screen(custom, max, 850) {
					font-size:14px;
				}
				strong {
					@include font-family($font-semibold);
					color:$color-carmine;
				}
			}
			.link-text {
				margin-left:16px;
				display:inline-block;
				font-size:14px;
				@include screen(custom, max, 850) {
					position:absolute;
					right:0;
					top:2px;
				}
			}
		}
	}
	/* 20191111 : LGEUS-11779 add */
	.apply-filters{
		 margin-top: 20px;
		 @include screen(custom, max, 850) {
		 	padding: 5px 24px 25px;
		 }
		 span {
		 	display: inline-block;
		    border: 1px solid #d9d9d9;
		    vertical-align: middle;
		    padding: 3px 8px 3px 10px;
		    margin-right: 5px;
		    margin-bottom: 5px;
		    height: 26px;
		    font-size: 14px;
		    line-height: 17px;
		    border-radius: 3px;
		    color: #6b6b6b;
		    //margin-top: 10px;
			a {
				display: inline-block;
			    vertical-align: bottom;
			    background: url('/lg5-common/images/common/icons/modal-close.svg') no-repeat 100% 0px;
			    width: 8px;
			    height: 8px;
			    background-size: 8px;
			    margin-left: 5px;
			    position: relative;
			    top: -4px;
			    color: #6b6b6b;
			    /* PJTWAUS-1 20200114 modify */
			    overflow:hidden;
			    text-indent:-9999em;
			    white-space:nowrap;
			    /* //PJTWAUS-1 20200114 modify */
			}

		 }
		 .link-text{
		 		display:none;
		 	&.active{
		 		display:inline-block;
		 		margin-left:11px;
		 		@include screen(custom, max, 850) {
		 			margin-left:0px;
		 			width:100%;
		 			margin-top:20px;
		 		}
		 	}
		 }

	}
	/* //20191111 : LGEUS-11779 add */
	.banner-box{
		position:relative;
		overflow:hidden;
		height:320px;
		margin-top:20px;
		&.text-black {
			background-color: $bg-white;  /* //PJTWAUS-1 modify  */	
		}
		&.text-white {
			background-color: $bg-gray;
		}
		@include screen(custom, min, $min-md) {
			&.text-black {				
				.text-block {
					.text, .sub-text {
						color:$color-black;
					}
					.btn-area {
						.btn {
							color:$color-black !important;
						}
						.btn-outline-light {
							border-color:$line-black !important;
							color:$color-black !important;
						}
					}
				}
			}
			&.text-white {				
				.text, .sub-text {
					color:$color-white;
				}
				.btn-area {
					.btn {
						color:$color-white !important;
					}
					.btn-outline-light {
						border-color:$color-white !important;
					}
				}
			}
		}
		@include screen(custom, max, $max-sm) {
			height:auto;		
			padding-bottom:34px;
			margin:0 24px 0 24px;
		} 
		
		.banner-image{
			z-index:1;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			img {
				@include screen(custom, max, $max-sm) {
					width:100%;
				}
			}
			@include screen(custom, max, $max-sm) {
				position:relative;
				margin-bottom:18px;
			} 
			//@include screen(custom, max, $max-xs) {
			//	position:relative;
			//	margin-bottom:18px;
			//} 
		}
		&.align-left,
		&.align-right {
			.text-block {
				left:0;
				width:(340px+100px);
				@include screen(custom, max, $max-sm) {
					width:100%;
					left:auto;
				}
			}
		}
		&.align-right {
			.text-block {
				left:auto;
				right:0;
				text-align:left;
				@include screen(custom, max, $max-sm) {
					right:auto;
				}
			}
			.banner-image {
				@extend %clearfix;
				img.pc {float:right;}
			}
		}
		&.align-center {
			.text-block {
				left:50%;
				text-align:center;
				transform:translate(-50%,-50%);
				width:(460px+100px);
				@include screen(custom, max, $max-sm) {
					width:100%;
					left:auto;
					transform:translate(0, 0);
				}
			}
			.banner-image {
				img.pc {
					position:absolute;
					top:0;
					left:50%;
					transform:translate(-50%, 0);
				}
			}
		}
		.text-block{
			z-index:1;
			position:absolute;
			top:50%;
			width:100%;
			transform:translate(0,-50%);
			padding:0 50px;
			@include screen(custom, max, $max-sm) {
				position:relative;
				top:auto;
				left:auto;
				width:100%;
				padding:0;
				transform:translate(0,0);
				text-align:center;
			} 
			.text-area{
				color:$color-white;
				@include screen(custom, max, $max-sm) {
					color:$color-black;
					br {
						display:none;
					}
				}
				.text{
					@include font-family($font-semibold);
					font-size:32px;
					line-height:36px;
					@include screen(custom, max, $max-sm) {
						font-size:24px;
						line-height:28px;
						
					}
				}
				.sub-text{
					margin-top:12px;
					font-size:16px;
					line-height:24px;
					@include screen(custom, max, $max-sm) {
						margin-top:8px;
					}
				}
			}
			.btn-area{
				margin-top:20px;
				.btn {
					color:$color-white !important;
					@include screen(custom, max, $max-sm) {
						width:100%;
						color:$color-black !important;
					}
				}
				.btn-outline-light{
					@include screen(custom, max, $max-sm) {
						border-color:$line-black !important;
					}
					
				}


			}
		}
	}
	.product-list-box{
		// overflow:hidden;
		position:relative;
		@include screen(custom, max, $max-xs) {
			padding:0 2px 0 2px;
		}
		.list-box{
			@extend %clearfix;
			position:relative;
			margin:0;
			@include screen(custom, max, $max-sm) {
				padding:1px 24px 22px;
			}
			@include screen(custom, max, 374) {
				// padding:1px 14px 9px;
				padding-left:14px;
				padding-right:14px;
			}
			li{
				position: relative;
				float:left;
				margin-top:-1px;
				width:34.17%;
				// min-height:717px;
				height:745px;
				&:nth-child(3n){
					padding-right:0;
				}
				@include screen(custom, min, $min-md){
					padding:29px 12px 25px;
				}
				@include screen(custom, min, 1070) {
					margin-bottom:-4px;
					&:nth-child(3n){
						width:31.66%;
					}
					&:nth-child(3n+1):before{
						content:"";
						position: absolute;
						top:0; left:0;
						display:block;
						width:calc(100% * 3 - 24px);
						height:1px;
						background-color: $line-lightgray;
					}
					&:nth-child(1):before{
						display:none;
					}
				}
				@include screen(custom, max, 1069) {
					width:50%;
					padding-left:18px;
					padding-right:18px;
					// min-height:690px;
					height:718px;
					margin-bottom: 24px;
					&:nth-child(odd):before{
						content:"";
						position: absolute;
						top:0; left:0;
						display:block;
						width:200%;
						height:1px;
						background-color: $line-lightgray;
					}
					&:nth-child(1):before{
						display:none;
					}
				}
				@include screen(custom, max, $max-md) {
					margin-bottom: 36px;
				}
				@include screen(custom, max, $max-sm) {
					// min-height:607px;
					height: 635px;
					padding:17px 0 26px 0px;
					margin-bottom: 0;
					&:nth-child(odd){
						padding-right:12px;
					};
					&:nth-child(even){
						padding-left:12px;
					};
				}
				.item {
					position:relative;
					text-align:center;
					.visual {
						position:relative;
						// max-width:200px;
						width:200px;
						height:200px;
						display:inline-block;
						@include screen(custom, max, $max-sm) {
							width:130px;
							height:130px;
						}
						img{
							width:100%;
							// @include screen(custom, max, $max-xs) {
							// 	width:calc(100% - 6px);
							// 	max-width:130px;
							// }	
						}
					}
					.tag-content {
						padding:9px 0 18px;
						margin:auto;
						height:59px;
						overflow: hidden;
						text-overflow:ellipsis;
						/* DC-677 Update */
						// white-space:nowrap;
						min-height: 59px;
						/* DC-677 Update end*/
						span {
						    display: inline-block;
							vertical-align: top;
							height: 20px;
							padding: 0 7px;
							border: 1px solid #a50034;
							border-radius: 3px;
							margin: 0 4px;
							font-size: 12px;
							line-height: 20px;
							color: #a50034;
							font-style: normal;
							font-weight: 700;
							@include font-family($font-bold);
							text-transform:uppercase;	
							/* DC-677 Update */
							overflow: hidden;
							/* DC-677 Update end*/						
						}
						@include screen(custom, max, $max-sm) {
							padding:5px 0 15px;
							height:42px;
							span {
								font-size:12px;
							}
						}
					}
					
					.model-group {
						margin:21px 0 0;
						height:28px;
						position: relative;
						overflow:hidden;
						.inner {
							display:inline-block;
							margin:0 auto;
							overflow:hidden;
							@extend %clearfix;
							a {
								display:inline-block;
								// outline:none;
								text-decoration:none;
								&.swatch {
									text-indent:-99999em;
									overflow: visible;
									position: relative;
									float: left;
									margin: 4px 5px 4px;
									width: 20px;
									height: 20px;
									padding: 0;
									color: transparent;
									cursor: pointer;
									vertical-align: top;
									border-radius: 10px;
									border:1px solid #9c9c9c;
									box-sizing:border-box;
									font-size:0;
									outline:0;									
									&:before {
										content: '';
										position:absolute;
										left:-5px;
										top:-5px;
										width:28px;
										height:28px;
										border:1px solid transparent;
										box-sizing:border-box;
										border-radius:14px;										
									}
									&:focus{
										&:before {
											content: '';
											position:absolute;
											left:-5px;
											top:-5px;
											width:28px;
											height:28px;
											border:2px solid #000;
											box-sizing:border-box;
											border-radius:14px;										
										}
									}
									&.active {		
										&:focus:before {
											left: -2px;
											top: -2px;
											width: 22px;
											height: 22px;
											border-radius: 11px;									
										}								
										&:after {
										content: '';
										position:absolute;
										left:-5px;
										top:-5px;
										width:28px;
										height:28px;
										border:1px solid $line-carmine;
										box-sizing:border-box;
										border-radius:14px;
										}
									}
									&:nth-child(n+7) {display:none;}
									@include screen(custom, max, $max-sm) {
										&:nth-child(n+5) {display:none;}
									}
								}
								&.size {	
									position:relative;								
									font-size:12px;
									line-height:22px;
									box-sizing:border-box;
									width:40px;
									height:22px;
									color:$color-dimgray;
									text-align:center;
									@include font-family($font-regular);
									border:1px solid #d9d9d9;
									border-radius:3px;
									margin:2px 1px 4px;
									outline:0;
									@include screen(custom, max, $max-sm) {
										width:30px;
										margin:2px 1px 4px;
									}
									@include screen(custom, max, 335) {width:28px;}
									/* PJTWAUS-1 20200113 modify */
									&.active {
										outline:0;
										color:$color-nightrider;	
										@include font-family($font-semibold);
										&:before {
											content:"";
											position: absolute;
											display:block;
										}
										&:after {											
											content:'';
											display:block;
											position:absolute;
											top:-1px;
											left:-1px;
											width:40px;
											height:22px;
											border-radius:3px;																					
											border:2px solid $line-dimgray;
											@include screen(custom, max, $max-sm) { 
												width:30px;
											}
										}
									}
									/* //PJTWAUS-1 20200113 modify */
									&:nth-child(n+5) {display:none;}
									@include screen(custom, max, $max-sm) {
										&:nth-child(n+5) {display:none;}
									}
									/* PJTWAUS-1 20200113 modify */
									&:focus {
										&:before {
											content: '';
											display: block;
											position: absolute;
											top: -1px;
											left: -1px;
											width: 40px;
											height: 22px;
											border-radius: 3px;
											border: 2px solid #000;
										}
										&.active {
											&:before {
												top:3px;
												left:3px;
												width:32px;
												height:14px;
												outline: -webkit-focus-ring-color auto 1px;												
												outline:focus-ring-color auto 1px;
												@include screen(custom, max, $max-sm) { 
													width:22px;
												}
											}
										}
									}
									/* //PJTWAUS-1 20200113 modify */									
								}
							}
							
						}
					}
					a.visual + .products-info {
						margin-top:25px;
						@include screen(custom, max, $max-sm) {
							margin-top:23px;
						}
					}
					.products-info {
						text-align:center;
						margin-top:10px;
						.model-name {
							font-size:18px;
							line-height:22px;
							color:$color-nightrider;
							width:95%;
							margin:auto;
							min-height:66px; /* PJTWAUS-1 20200311 modify */
							overflow:hidden;
							text-overflow: ellipsis;
							display:block;
							display:-webkit-box;
							-webkit-line-clamp:3;
							-webkit-box-orient:vertical;
							a {
								color:$color-nightrider;
							}
						}
						.sku {
							margin:10px 0 0;
							min-height:18px;
							font-size:14px;
							line-height:18px;
							color:$color-dimgray;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow: hidden;
						}
						.rating {
							max-width:210px;
							margin:6px auto 0;
							height:30px;
							.bv_stars_component_container {
								line-height: 11px!important;
							}
						}
						.price-area {
							/* LGEUS-745 20200715 eidt */
							min-height:40px;
							/* // LGEUS-745 20200715 eidt */
							margin:auto;
							text-align:center;
							max-width:210px;
							.msrp,
							.purchase-price,
							.product-price{
								display:inline-block;
								vertical-align:middle;
							}
							.msrp {
								@include font-family($font-semibold);
								font-size:14px;
								line-height:32px;
								color:$color-dimgray;
								text-transform:uppercase;
								padding-right:4px;
								@include screen(custom, max, $max-md) {
									line-height:24px;
								}
							}
							.purchase-price {
								font-size:24px;
								line-height:32px;
								height:32px;
								color:$color-black;
								.price {padding-right:4px;}
							}
							.product-price {
								min-height:32px;
								.price,
								.legal {
									text-align:left;
									@include font-family($font-semibold);
									font-size:14px;
									line-height:16px;
									min-height:16px;
								}
								.price {
									color:$color-dimgray;
									text-decoration:line-through;
								}
								.legal {color:$color-carmine;}
							}
						}
						// non price 
						.retailer {
							height:32px;
							font-size:16px;
							color:$color-black;
							@include font-family($font-semibold);
							@include screen(custom, max, $max-md) {
								height:auto;
								min-height:51px;
							}
							@include screen(custom, max, $max-sm) {
								font-size:14px;
							}
						}/* DC-1468 Update */
						// .promotion-text {
						// 	font-size:14px;
						// 	line-height:22px;
						// 	color:$color-nightrider;
						// 	min-height:34px; /* PJTWAUS-1 20200312 modify */
						// 	padding-top:4px;
						// 	margin:0 auto;
						// 	max-width:210px;
						// 	text-align:center;
						// 	overflow: hidden;
						// 	text-overflow:ellipsis;
						// 	white-space:nowrap;
						// 	@include font-family($font-bold);
						// }
						/* DC-1468 Update end */
						.button {
							margin:auto;
							max-width:210px;
							min-height:80px;
							/* DC-1468 Update */
							margin-top: 20px;
							/* DC-1468 Update end */
							a {
								width:100%;
								margin-top:0;
								&:first-child {margin-top:0;}
								display:none;
								&.active {display:inline-block;}
							}
							a + a.active:not(.preorder),
							a:not(.active) + a:not(.active):not(.preorder) + a.find-a-dealer.active {
								margin-top:45px;
							}
							a.active + a.preorder:not(.active) + a.active {
								margin-top:10px;
							}
							a.active + a.active,
							a + a.find-a-dealer.active {
								margin-top:10px;
							}
							a + a.inquiry-to-buy.active {
								margin-top: 0;
							}
						}
						.wishlist-compare {
							position:relative;
							margin-top:17px;
							text-align:center;
							// @include screen(custom, max, $max-sm) {
							// 	margin-top:18px;
							// }
							a {
								display:inline-block;
								vertical-align:top;
								margin-left:((16px*100%)/321px);
								&:first-child {
									margin-left:0;
									// @include screen(custom, max, $max-sm) {
									// 	display:none;
									// }
								}
							}
							.link-text {
								text-transform:uppercase;
								@include screen(custom, max, $max-sm) {
									&.ico-compare{
										span:first-child {
											//position:relative;
											width:auto;
											height:auto;
										}
									}
									
								}
							}
						}
					}
					
					@include screen(custom, max, $max-md) {
						.products-info {
							.price-area {
								display:block;
								min-height:51px;
								.purchase-price {
									display:inline-block;
									margin-top:2px;
									// width:100%;
									text-align:center;
									font-size:16px;
									line-height:24px;
									height:24px;
									color:$color-black;
									.price {padding-right:0;}
								}
								.product-price {
									position: relative;
									display: flex;
									justify-content: center;
									min-height:19px;
									.price {padding-left:0;}
									.price,
									.legal {display:inline;white-space:nowrap;line-height:15px;min-height:19px;padding-top:2px;padding-left:4px;}
								}
							}
							.promotion-text {
								height: 35px;
								margin-top:-7px;
							}
							.wishlist-compare {
								a {
									margin-left:0;
									/*
									&:first-child {
										margin-right:29px;
										&:after {
											content:'';
											position:absolute;
											top:4px;
											left:150%;
											width:1px;
											height:13px;
											background-color:#d9d9d9;
											display:inline-block;
										}
									}
									*/
								}
								/*
								.link-text.ico-wishlist span,
								.link-text.ico-compare span{
									position:absolute;
									width:1px;
									height:1px;
									padding:0;
									overflow:hidden;
									clip:rect(0,0,0,0);
									white-space:nowrap;
									border:0;
								}
								*/
							}
						}
					}
					@include screen(custom, max, $max-sm) {
						padding: 0 !important;
						.items {
							.item {
								width:50%;
								margin:0;
								padding:0 12px 0 14px;
								&:nth-child(2n) {padding:0 14px 0 12px;}
								.visual {
									max-width:130px;
								}
							}
						}
						.products-info {
							.model-name {
								font-size:14px;
								line-height:18px;
								height:74px;
								-webkit-line-clamp:4;
							}
							.rating {
								/* DC-1468 Update */
								height:52px;
								.bv_numReviews_component_container {
									display: block!important;
								}
								/* DC-1468 Update end */
								margin:0 auto;
								width: 110%;
								
							}
							.button {
								a {
									padding-left:0;
									padding-right:0;
								}
							}
						}	

					}
					.rebate-box{
						margin-top:8px;
						@include screen(custom, max, $max-sm) {
							margin-top:6px;
						}
					}
				}
			}
		}	
		.pagination{
			margin-bottom:50px;
			@include screen(custom, max, $max-sm) {
				//margin-top:0;
			}

			a, button {
				&:disabled {
					display: none;
				}
			}
		}
		// more button custom
		.expander {
			padding:0 24px 40px;
			@include screen(custom, max, $max-sm) {
				padding:0 24px 40px;
			}
			@include screen(custom, max, 374) {
				padding:0 14px 40px;
			}
		}
		// no-content
		.no-content {
			display: none;
		}
	}
}
#resultAppendTarget {
	.no-content {
		display: none;
	}
}
// no filter
.no-filter{
	.result-box {
		float:none;
		width:100%;
		.product-list-box .list-box li {
			border-bottom:none;
			@include screen(custom, min, 1070){
				width:25%;
				&:nth-child(3n+1):before{
					display:none;
				}
				&:nth-child(4n+1):not(:first-child):before{
					content:"";
					position: absolute;
					top:0; left:0;
					display:block;
					width:400%;
					height:1px;
					background-color: $line-lightgray;
				}
			}
			// @include screen(custom, min, $min-md){
			// 	padding:29px 12px 25px;
			// }
		}
	}
}
/*  PJTWAUS-1 20200113 modify  */
.tooltip-box{
	position: absolute;
	display:block;
	background:#333;
	font-size:14px;
	line-height:16px;
	padding:5px 15px;
	border:1px solid #333;	 
	color:#fff;
	border-radius:3px;
	z-index: 9999;
	//box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
	.arrow{
		display: inline-block;
		position: absolute;
		left: 50%;
		top:-6px;
		width:18px;
		height:14px;
		margin-left:-7px;
		border: 1px solid #333;
		background:#333;
		border-left: 0;
		border-top: 0;
		transform:rotate(45deg);
		-webkit-transform:rotate(45deg);
		-moz-transform:rotate(45deg);
		z-index: 1;
	}
	.text{
		position: relative;
		z-index: 2;
	}
}
/* //PJTWAUS-1 20200113 modify  */
